<template>
  <div class="csn-home">
    <div>
      <div class="row csn-home-row">
        <div class="col-md-8 col-sm-12 casino-intro csn-slider-container">
          <SlideShow :list="SLIDE_LIST" />
        </div>
        <div class="csn-slider-mobile-container"></div>
        <GameNotifications />
      </div>
    </div>

    <Breadcrumbs />
    <div class="casino-homepage-game csn-home-game-list-container" :ref="ref">
      <HorizontalList
        v-if="isLogged"
        :title="t('recently_played')"
        :itemsPerScreen="itemsPerScreen"
        :getList="getRecentGameList"
      />
      <HorizontalList
        v-if="isLogged"
        :title="t('favourites')"
        :itemsPerScreen="itemsPerScreen"
        :getList="getFavouriteGameList"
      />
      <HorizontalList
        :title="t('top_games')"
        :itemsPerScreen="itemsPerScreen"
        :showAllRouteName="RouteName.GAME_TOP_GAMES"
        :getList="getTopGameList"
      />
      <HorizontalList
        :title="t('providers')"
        :itemsPerScreen="itemsPerScreen"
        :getList="getGameVendors"
        :isProviderList="true"
      />
      <HorizontalList
        :title="t('new_games')"
        :itemsPerScreen="itemsPerScreen"
        :showAllRouteName="RouteName.GAME_NEW_GAMES"
        :getList="getNewGameList"
      />
      <HorizontalList
        :title="t('live_casino')"
        :itemsPerScreen="itemsPerScreen"
        :showAllRouteName="RouteName.GAME_FREE_PLAY"
        :getList="getLiveCasinoGameList"
      />
      <HorizontalList
        :title="t('slots')"
        :itemsPerScreen="itemsPerScreen"
        :showAllRouteName="RouteName.GAME_FREE_PLAY"
        :getList="getSlotList"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { HOME, Module, IS_LOGGED, SLIDE_LIST, RouteName } from '@/constants'
import { dest, getVisibleGameCardNumber } from '@/helpers'
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'

export default {
  name: HOME,
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    Breadcrumbs: () => import('@/components/Breadcrumbs'),
    GameNotifications: () => import('@/components/GameNotifications'),
    SlideShow: () => import('@/components/SlideShow'),
    HorizontalList: () => import('@/components/HorizontalList'),
  },
  computed: {
    ...mapState(Module.SLIDE, [SLIDE_LIST]),
    ...mapGetters({
      isLogged: dest([Module.USER, IS_LOGGED]),
    }),
    RouteName: () => RouteName,
    t() {
      return this.$createComponentTranslator(HOME)
    },
    itemsPerScreen() {
      return getVisibleGameCardNumber(this.containerWidth)
    },
  },
  methods: {
    getLiveCasinoGameList: GameApi.getLiveCasinoGameList,
    getSlotList: GameApi.getSlotList,
    getNewGameList: GameApi.getNewGameList,
    getTopGameList: GameApi.getTopGameList,
    getFavouriteGameList: GameApi.getFavouriteGameList,
    getRecentGameList: GameApi.getRecentGameList,
    getGameVendors: GameApi.getGameVendors,
  },
}
</script>
